import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      mug: file(relativePath: { eq: "mug-with-quote.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  `);
  const mug = getImage(data.mug);
  return (
    <div className="container flex flex-row lg:flex-nowrap flex-wrap md:py-20 py-10 md:gap-8 gap-5 items-center">
      <div className="flex flex-col lg:basis-1/3 basis-full">
        <h3
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-easing="ease"
          className="text-display-sm font-semibold text-primary-1 mb-2"
        >
          Passionate. Creative. Committed.
        </h3>
        <p
          className="md:text-body-lg text-body-md text-neutral-2"
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          With a team of young creative, dynamic and dedicated professionals, we
          have pushed all areas of event management to new frontiers. We are a{" "}
          <b>One Stop</b> for any and every requirement to our wide spectrum of
          clients.
        </p>
      </div>
      <div
        className="flex lg:basis-1/3 md:basis-3/4 basis-full"
        data-sal="fade"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        <GatsbyImage image={mug} alt="Quote in a mug" className="rounded" />
      </div>
      <div className="flex flex-col lg:basis-1/3 basis-full gap-10">
        <div>
          <h4
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
            className="text-display-xs font-semibold text-primary-3 mb-2"
          >
            Our Vision
          </h4>
          <p
            className="text-body-lg text-neutral-2"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            To be a fully integrated marketing company offering value added
            services with the highest standards and goals.
          </p>
        </div>
        <div>
          <h4
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="text-display-xs font-semibold text-primary-3 mb-2"
          >
            Our Mission
          </h4>
          <p
            className="text-body-lg text-neutral-2"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="500"
            data-sal-easing="ease"
          >
            Be a one-stop solution for corporate clients. Build long-term
            business friendships that will remain.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
