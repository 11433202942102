import React from "react";
import About from "../components/about";
import Hero from "../components/hero";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Services from "../components/services";
import Works from "../components/works";
import WhatsAppBubble from "../components/whatsapp";
import Clients from "../components/clients";
import Cta from "../components/cta";

const IndexPage = ({ data }) => (
  <div>
    <Layout showHome={false}>
      <Seo
        title="Scarlet Events Bangalore"
        keywords={[`developer`, `engineer`, `react`, `javascript`]}
      />
      <Hero />
      <About />
      <Services />
      <Works />
      <Clients />
      <Cta />
      <WhatsAppBubble />
    </Layout>
  </div>
);

export default IndexPage;
