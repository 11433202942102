import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Button from "./Button";

const Cta = () => (
  <div className="container md:mb-20 mb-10">
    <div
      className="rounded-lg bg-accent-blue-light flex flex-row overflow-hidden relative"
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      <div className="lg:py-12 lg:pl-12 md:py-10 md:pl-10 md:pr-0 p-8 md:w-[52%] w-full">
        <h2 className="md:text-display-md text-display-sm font-bold text-primary-1 md:mb-4 mb-2">
          Have your next event here.
        </h2>
        <p className="lg:text-body-xl md:text-body-lg text-body-md text-neutral-2 md:mb-8 mb-4">
          Let’s join together and make your next event a remarkable one.
        </p>
        <Button url="mailto:info@scarletevent.com" label="Drop a mail" />
      </div>
      <svg
        className="absolute lg:block hidden bottom-[25%] right-[41%]"
        width="162"
        height="161"
        viewBox="0 0 162 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9859 35.5114C14.9859 35.5114 16.7383 71.4143 39.308 84.3381C55.2318 93.4563 75.9771 87.9876 75.4958 75.7778C75.2413 69.3196 68.3035 68.1061 64.0894 70.5929C56.473 75.0874 61.0025 94.4066 69.3688 102.896C98.385 132.339 153.835 110.545 153.835 110.545"
          stroke="#161B46"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M149.669 119.083L154.598 110.623L145.688 104.808"
          stroke="#161B46"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="absolute lg:-top-[22%] -top-[4%] lg:-right-[15%] -right-[18%] lg:w-[500px] w-[380px] md:block hidden">
        <StaticImage src="../images/event.png" alt="Event" />
      </div>
      <div className="absolute lg:-bottom-[22%] -bottom-[32%] lg:right-[22%] right-[18%] rotate-[10deg] w-[200px] md:block hidden">
        <StaticImage src="../images/your-next-event.png" alt="Next Event" />
      </div>
    </div>
  </div>
);

export default Cta;
