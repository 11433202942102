import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Works = () => {
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "works/ldra-seminar.png" }) {
        childImageSharp {
          gatsbyImageData(width: 510)
        }
      }
      image2: file(relativePath: { eq: "works/captia-bash.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      image3: file(
        relativePath: { eq: "works/medical-conferene-delegates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 320)
        }
      }
      image4: file(relativePath: { eq: "works/captia-bash-2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 320)
        }
      }
      image5: file(relativePath: { eq: "works/ldra-stall.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
      image6: file(relativePath: { eq: "works/medical-conferene.png" }) {
        childImageSharp {
          gatsbyImageData(width: 510)
        }
      }
    }
  `);

  return (
    <div className="container md:py-20 py-10">
      <h2
        data-sal="slide-up"
        data-sal-duration="500"
        data-sal-delay="300"
        data-sal-easing="ease"
        className="md:text-display-md text-display-sm font-bold text-primary-1 text-center mb-12"
      >
        Making memories over time
      </h2>
      <div className="flex md:flex-row flex-col md:gap-8 gap-4 md:px-8 px-0">
        <div
          className="rounded overflow-hidden"
          data-sal="fade"
          data-sal-duration="500"
          data-sal-easing="ease"
        >
          <GatsbyImage image={getImage(data.image1)} alt="image" />
        </div>
        <div className="flex flex-col md:gap-8 gap-4">
          <div className="flex md:flex-row flex-col md:gap-8 gap-4 basis-1/2">
            <div
              className="md:w-[68.6%] w-full rounded overflow-hidden"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <GatsbyImage
                image={getImage(data.image2)}
                alt="image"
                className="h-full"
              />
            </div>
            <div
              className="md:w-[31.4%] w-full rounded overflow-hidden"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <GatsbyImage
                image={getImage(data.image3)}
                alt="image"
                className="h-full"
              />
            </div>
          </div>
          <div className="flex md:flex-row flex-col md:gap-8 gap-4 basis-1/2">
            <div
              className="md:w-[31.4%] w-full rounded overflow-hidden"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <GatsbyImage image={getImage(data.image4)} alt="image" />
            </div>
            <div
              className="md:w-[68.6%] w-full rounded overflow-hidden"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <GatsbyImage image={getImage(data.image5)} alt="image" />
            </div>
          </div>
        </div>
        <div
          className="rounded overflow-hidden"
          data-sal="fade"
          data-sal-duration="500"
          data-sal-easing="ease"
        >
          <GatsbyImage image={getImage(data.image6)} alt="image" />
        </div>
      </div>
    </div>
  );
};

export default Works;
