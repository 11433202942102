import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactCompareImage from "react-compare-image";
import Button from "../components/Button";

const Hero = ({ images }) => {
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "right-image.jpeg" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
      image2: file(relativePath: { eq: "left-image.jpeg" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);
  return (
    <div className="container">
      <div className="flex lg:flex-row flex-col items-center lg:gap-30 md:gap-20 gap-8 pb-20 lg:h-[80vh] border-b border-neutral-3">
        <div className="lg:mt-0 md:mt-16 mt-8">
          <h1
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
            className="text-primary-1 md:mb-4 mb-2 md:text-display-xl text-display-md font-bold"
          >
            Turning Ideas Into Action
          </h1>
          <p
            className="md:text-body-xl text-body-md md:mb-8 mb-4 text-neutral-2"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Organising events with meticulous forethought and drafting amidst
            demands.
          </p>
          <Button
            url="mailto:info@scarletevent.com"
            label="Drop a mail"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="500"
            data-sal-easing="ease"
          />
        </div>
        <svg
          className="absolute -top-[8%]  md:right-[44%] right-[8%] -z-[1] md:w-[160px] md:h-[160px] w-[120px] h-[120px] md:opacity-100 opacity-40"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="80" cy="80" r="80" fill="#FFC3D8" />
        </svg>
        <svg
          className="absolute right-[1%] top-[75%] -z-[1]"
          data-sal="zoom-in"
          data-sal-duration="500"
          data-sal-easing="ease"
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="36" cy="36" r="36" fill="#FFD9CA" />
        </svg>
        <svg
          className="absolute lg:right-[5%] md:right-[24%] right-[24%] lg:top-[11%] md:top-[20%] top-[40%] -z-[1] md:w-[640px] md:h-[640px] w-[420px] h-[420px]"
          data-sal="zoom-in"
          data-sal-duration="500"
          data-sal-easing="ease"
          width="640"
          height="640"
          viewBox="0 0 640 640"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="320" cy="320" r="320" fill="#A0DCFF" />
        </svg>
        <div className="block lg:w-[1000px] w-full lg:-mr-24 rounded-md shadow-image-slider overflow-hidden border-[3px] border-white z-10 ImageSlider">
          <ReactCompareImage
            rightImage={data.image1.childImageSharp.original.src}
            leftImage={data.image2.childImageSharp.original.src}
            sliderLineWidth="3"
            handleSize="36"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
