import React from "react";

const Button = ({ url, label, ...props }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-block text-body-md font-semibold px-6 py-4 rounded-full bg-primary-4 text-white"
    {...props}
  >
    {label}
  </a>
);

export default Button;
