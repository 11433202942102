import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Clients = () => {
  const data = useStaticQuery(graphql`
    {
      corporate: allFile(
        filter: { relativeDirectory: { eq: "corporate" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
      medical: allFile(
        filter: { relativeDirectory: { eq: "medical" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `);
  return (
    <div className="container md:py-20 py-10">
      <h2
        data-sal="slide-up"
        data-sal-duration="500"
        data-sal-delay="300"
        data-sal-easing="ease"
        className="md:text-display-md text-display-sm font-bold text-primary-1 text-center mb-12"
      >
        Trusted by our beloved clients
      </h2>
      <div className="mb-12">
        <h4
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="text-display-xs font-semibold text-primary-3 text-center mb-4"
        >
          Medical
        </h4>
        <div className="flex flex-row justify-center gap-4">
          {data.medical.edges.map(({ node }) => (
            <img
              src={node.publicURL}
              alt={node.name}
              key={node.id}
              width={120}
              height={60}
              className="border border-neutral-4"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease"
            />
          ))}
        </div>
      </div>
      <div>
        <h4
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="text-display-xs font-semibold text-primary-3 text-center mb-4"
        >
          Corporate
        </h4>
        <div className="flex flex-row flex-wrap justify-center gap-4">
          {data.corporate.edges.map(({ node }) => (
            <img
              src={node.publicURL}
              alt={node.name}
              key={node.id}
              width={144}
              height={72}
              className="border border-neutral-4"
              data-sal="fade"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
