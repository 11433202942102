import React from "react";

const WhatsAppBubble = () => (
  <a href="https://wa.me/918123484553">
    <svg
      className="fixed md:bottom-12 bottom-6 md:right-12 right-6 rounded-full z-10 md:w-16 md:h-16 w-12 h-12"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill="#25D366"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9888 46.6906H30.9815C27.9959 46.6896 25.0623 45.9405 22.4567 44.5193L13 47L15.5308 37.7561C13.9697 35.0506 13.1482 31.9819 13.1496 28.8378C13.1535 19.0021 21.156 11 30.9887 11C35.7608 11.0021 40.2398 12.8598 43.6078 16.2318C46.9757 19.6035 48.8296 24.0854 48.8277 28.8519C48.8237 38.6853 40.8244 46.6866 30.9888 46.6906ZM22.8948 41.2899L23.4363 41.6112C25.7127 42.9621 28.3222 43.6769 30.9828 43.6779H30.9888C39.1611 43.6779 45.8124 37.0264 45.8157 28.8508C45.8172 24.889 44.2764 21.1639 41.4771 18.3612C38.6777 15.5586 34.955 14.0144 30.9947 14.013C22.8162 14.013 16.1647 20.6639 16.1615 28.8388C16.1603 31.6405 16.9442 34.3689 18.4284 36.7295L18.781 37.2905L17.2831 42.7619L22.8948 41.2899ZM39.976 33.0939C39.8646 32.908 39.5675 32.7964 39.1217 32.5732C38.676 32.3502 36.4846 31.272 36.0759 31.1231C35.6674 30.9744 35.3702 30.9001 35.0731 31.3462C34.776 31.7923 33.9217 32.7964 33.6617 33.0939C33.4017 33.3913 33.1417 33.4287 32.6961 33.2055C32.2504 32.9824 30.8141 32.5117 29.1116 30.9931C27.7865 29.8111 26.8919 28.3516 26.6319 27.9053C26.3719 27.4591 26.6042 27.2179 26.8273 26.9957C27.0279 26.7959 27.2731 26.4749 27.4959 26.2147C27.7188 25.9545 27.7931 25.7685 27.9417 25.4712C28.0902 25.1736 28.016 24.9134 27.9045 24.6903C27.7931 24.4671 26.9017 22.2731 26.5302 21.3806C26.1684 20.5115 25.8009 20.6292 25.5273 20.6155C25.2676 20.6024 24.9702 20.5997 24.6731 20.5997C24.3759 20.5997 23.893 20.7113 23.4844 21.1575C23.0759 21.6038 21.9244 22.6821 21.9244 24.876C21.9244 27.07 23.5216 29.1895 23.7444 29.4869C23.9673 29.7845 26.8875 34.2866 31.3589 36.2174C32.4223 36.6766 33.2525 36.9509 33.8999 37.1563C34.9677 37.4956 35.9395 37.4477 36.7074 37.3329C37.5638 37.205 39.3445 36.2547 39.7161 35.2136C40.0874 34.1722 40.0874 33.2798 39.976 33.0939Z"
        fill="#FDFDFD"
      />
    </svg>
  </a>
);

export default WhatsAppBubble;
