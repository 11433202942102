import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Services = () => {
  const data = useStaticQuery(graphql`
    {
      services: file(relativePath: { eq: "all-services.png" }) {
        childImageSharp {
          gatsbyImageData(width: 540)
        }
      }
    }
  `);
  const services = getImage(data.services);
  return (
    <div className="container md:py-20 py-10 flex flex-col items-center">
      <h2
        data-sal="slide-up"
        data-sal-duration="500"
        data-sal-easing="ease"
        className="md:text-display-md text-display-sm font-bold text-primary-1 text-center mb-12"
      >
        An Overview of Event Strategy
      </h2>
      <div className="flex md:flex-row flex-col gap-8 items-center">
        <div
          className="md:basis-1/2 basis-full"
          data-sal="fade"
          data-sal-duration="500"
          data-sal-easing="ease"
        >
          <GatsbyImage image={services} alt="Services" />
        </div>
        <div className="basis-1/2 flex flex-col gap-10">
          <div>
            <div
              className="flex items-center mb-2"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <div className="w-1.5 h-1.5 rounded-full mr-2.5 bg-accent-pink-dark"></div>
              <h4 className="text-display-xs font-semibold text-primary-3">
                Event Management
              </h4>
            </div>
            <p
              className="text-body-lg text-neutral-2"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              Creative solutions to enhance customer engagement experiences for
              various event management needs including product launches,
              corporate conferences & seminars, award recognition programs and
              annual events.
            </p>
          </div>
          <div>
            <div
              className="flex items-center mb-2"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <div className="w-1.5 h-1.5 rounded-full mr-2.5 bg-accent-orange-dark"></div>
              <h4
                className="text-display-xs font-semibold text-primary-3"
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                Brand Activation
              </h4>
            </div>
            <p
              className="text-body-lg text-neutral-2"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="500"
              data-sal-easing="ease"
            >
              Be it roadshows, promotions, mall activation, or consumer contact
              programs, we provide holistic approach and flawless execution of
              activation services catering to all verticals.
            </p>
          </div>
          <div>
            <div
              className="flex items-center mb-2"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="500"
              data-sal-easing="ease"
            >
              <div className="w-1.5 h-1.5 rounded-full mr-2.5 bg-accent-blue-dark"></div>
              <h4 className="text-display-xs font-semibold text-primary-3">
                Celebrations
              </h4>
            </div>
            <p
              className="text-body-lg text-neutral-2"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="500"
              data-sal-easing="ease"
            >
              Bringing unforgettable experiences that builds a memory for
              various occasions which includes DJ Parties, themed events,
              family/corporate get-together, and more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
